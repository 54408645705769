.login-wrapper{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: start;
  
    .login-card{
      position: relative;
      // -webkit-backdrop-filter: blur(10px);
      // backdrop-filter: blur(10px);
      border-radius: 1rem;
      // height: 520px;
      width: 920px;
  
      display: flex;
      background-color: white;
  
      // img{
      //   width: 460px;
      //   border-radius: 1rem 0 0 1rem;
      // }
  
      .login-card2{
        height: 520px;
        width: 460px;
        // -webkit-backdrop-filter: blur(10px);
        // backdrop-filter: blur(10px);
        padding: 2rem 3rem;
        // border-radius: 1rem;
  
        display: flex;
        justify-content: center;
        align-items: center;
        .mobile-login-logo{
          display: none;
        }
        .label{
          color: black;
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px){
    .login-wrapper{
      width: 92%;
      margin: 0 auto;
      .mobile-login-logo{
        display: block !important;
        text-align: center;
      }
      .login-card{
        height: auto;
        width: 100%;
        .login_title_card{
          display: none;
        }
        .login-card2{
          width: 100%;
          height: auto;
          padding: 30px 20px 40px 20px;
        }
      }
    }
  }


  .left-fixed-elemet{
    position: absolute;
    left: 0;
    z-index: -1;
    .first-box{
      background: #d6273e;
      width: 150px;
      border-radius: 0 100px 100px 0;
      height: 35px;
      margin-bottom: 35px;
    }
    .second-box{
      background: #1C89C1;
      width: 200px;
      border-radius: 0 100px 100px 0;
      height: 35px;
      margin-bottom: 35px;
    }
    .third-box{
      background: #144176;
      width: 300px;
      border-radius: 0 100px 100px 0;
      height: 35px;
      margin-bottom: 35px;
    }
  }
  
  .right-fixed-elemet{
    text-align: right;
    position: absolute;
    z-index: -1;
    right: 0;
    .first-box{
      background: #d6273e;
      right: 0;
      width: 150px;
      border-radius: 100px 0 0 100px;
      height: 35px;
      margin-bottom: 35px;
    }
    .second-box{
      background: #1C89C1;
      width: 200px;
      border-radius: 100px 0 0 100px;
      height: 35px;
      margin-bottom: 35px;
    }
    .third-box{
      background: #144176;
      width: 300px;
      border-radius: 100px 0 0 100px;
      height: 35px;
      margin-bottom: 35px;
    }
  }
  